import { Subscription } from '@/lib/api';

export const getCreditsForSubscription = (subscription: Subscription) => {
  switch (subscription) {
    case Subscription.PREMIUM:
      return 50;
    case Subscription.BASIC:
      return 3;
    case Subscription.ULTIMATE:
      return 100;
    case Subscription.HTE_ANNUAL:
      return 1000;
    default:
      return 0;
  }
};

export const getCreditsPriceCoefficient = (subscription: Subscription) => {
  switch (subscription) {
    case Subscription.BASIC:
      return 3;
    case Subscription.PREMIUM:
      return 2;
    case Subscription.ULTIMATE:
      return 1;
    case Subscription.HTE_ANNUAL:
      return 1;
    default:
      return 0;
  }
};
