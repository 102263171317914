import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiError, SignUpPayload, User, signUp } from '@/lib/api';

const postSignUp = async (payload: SignUpPayload) => {
  const { data } = await signUp(payload);
  return data;
};

export const useSignUp = () => {
  const queryClient = useQueryClient();

  return useMutation<User, ApiError, SignUpPayload, unknown>({
    mutationFn: postSignUp,
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });
};
