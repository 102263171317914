import { useEffect, useState } from 'react';
import { Link, Navigate, Outlet, useLocation } from 'react-router-dom';

import { ScrollArea } from '@radix-ui/react-scroll-area';
import { getCurrentScope } from '@sentry/react';
import {
  Heart,
  LayoutDashboard,
  LifeBuoy,
  Menu,
  PackageSearch,
  ScanSearch,
  Users,
} from 'lucide-react';

import Logo from '@/assets/icons/logo.svg?react';
import { CreditsCard } from '@/components/credits-card';
import { Nav } from '@/components/nav';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { UserMenu } from '@/components/user-menu';
import { ROUTES } from '@/constants/routes';
import { useUserContext } from '@/context/user';
import { useUser } from '@/features/auth';
import { useFavorites } from '@/features/favorites';
import { useSellers } from '@/features/sellers';
import { FavoritesType, PaymentStatus } from '@/lib/api';
import { isDevelopment, isStaging } from '@/utils/environment';

// TODO: make it collapsible
export const DefaultLayout = () => {
  const { user, setUser } = useUserContext();

  const { pathname } = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // NOTE: update local user data
  const { data: userData } = useUser();

  const { data: suppliersData, isLoading: isSuppliersLoading } = useSellers({
    type: FavoritesType.SUPPLIER,
  });

  const { data: distributorsData, isLoading: isDistributorsLoading } =
    useSellers({
      type: FavoritesType.DISTRIBUTOR,
    });

  const {
    data: suppliersFavoritesData,
    isLoading: isSuppliersFavoritesLoading,
  } = useFavorites(FavoritesType.SUPPLIER);

  const {
    data: distributorsFavoritesData,
    isLoading: isDistributorsFavoritesLoading,
  } = useFavorites(FavoritesType.DISTRIBUTOR);

  // NOTE: update user data, cause additional rerender
  useEffect(() => {
    if (userData) {
      setUser((user) => ({ ...user, ...userData }));
    }
  }, [userData, setUser]);

  // NOTE: sentry integration
  useEffect(() => {
    const scope = getCurrentScope();

    if (user) {
      scope.setUser({
        email: user.email,
        name: user.name,
        customerId: user.customerId,
        paymentStatus: user.paymentStatus,
        subscription: user.subscription,
        subscriptionExpiresAt: user.subscriptionExpiresAt,
      });
    }
  }, [user]);

  // NOTE: churnkey integration
  useEffect(() => {
    if (userData && userData?.customerId && window.churnkey?.check) {
      const churnkeyConfig = {
        customerId: userData.customerId,
        authHash: userData.churnkeyHash,
        appId: 'gc9wahf9b',
        mode: isDevelopment || isStaging ? 'test' : 'live',
        provider: 'stripe',
      };

      window.churnkey.check('pause', {
        ...churnkeyConfig,
        softWall: true, // set to true to allow exiting wall
        forceCheck: false, // recommended to leave this to false to avoid redundant checks
      });

      window.churnkey.check('failed-payment', {
        ...churnkeyConfig,
        softWall: true, // set to true to allow exiting wall
        forceCheck: false, // recommended to leave this to false to avoid redundant checks
        gracePeriodDays: 0, // allow a grace period in which failed payment wall is shown but can be exited (soft wall)
        ignoreInvoicesWithoutAttempt: false, // set to true to ignore invoices without a failed charge
      });
    }
  }, [userData]);

  useEffect(() => {
    setIsMenuOpen(false);
    window.scrollTo(0, 0);
  }, [pathname]);

  if (!user) return <Navigate to={ROUTES.SIGN_IN} replace={true} />;

  const isPaymentCanceled = user.paymentStatus === PaymentStatus.CANCELED;
  const isSubscriptionActive =
    new Date(user.subscriptionExpiresAt) > new Date();
  const isUserSubscriptionValid =
    user.subscription && (!isPaymentCanceled || isSubscriptionActive);

  // NOTE: redirect to subscription page if user has no subscription
  if (
    !isUserSubscriptionValid &&
    pathname !== ROUTES.SUBSCRIPTION &&
    pathname !== ROUTES.SUBSCRIPTION_SUCCESS
  ) {
    return <Navigate to={ROUTES.SUBSCRIPTION} replace={true} />;
  }

  return (
    <div className="grid gap-6 p-4 lg:grid-cols-[minmax(200px,_220px)_1fr_1fr_1fr_1fr] lg:p-6">
      <aside className="sticky top-6 hidden h-[calc(100dvh-48px)] flex-col justify-between lg:col-span-1 lg:flex">
        <div>
          <Link to="/" className="mb-6 flex items-center gap-2">
            <Logo className="size-8" />
            <span className="text-lg font-semibold">Supplier HQ</span>
          </Link>
          <Nav
            disabled={!isUserSubscriptionValid}
            links={[
              {
                title: 'Dashboard',
                icon: PackageSearch,
                subLinks: [
                  {
                    title: 'Suppliers',
                    to: ROUTES.SUPPLIERS,
                    label: isSuppliersLoading
                      ? null
                      : suppliersData?.rowCount?.toString() ?? '0',
                  },
                  {
                    title: 'Distributors',
                    to: ROUTES.DISTRIBUTORS,
                    label: isDistributorsLoading
                      ? null
                      : distributorsData?.rowCount?.toString() ?? '0',
                  },
                ],
              },
              {
                title: 'Product Explorer',
                to: ROUTES.PRODUCT_CATEGORIES,
                icon: LayoutDashboard,
              },
              {
                title: 'Favorites',
                icon: Heart,
                subLinks: [
                  {
                    title: 'Suppliers',
                    to: ROUTES.FAVORITE_SUPPLIERS,
                    label: isSuppliersFavoritesLoading
                      ? null
                      : suppliersFavoritesData?.length?.toString() ?? '0',
                  },
                  {
                    title: 'Distributors',
                    to: ROUTES.FAVORITE_DISTRIBUTORS,
                    label: isDistributorsFavoritesLoading
                      ? null
                      : distributorsFavoritesData?.length?.toString() ?? '0',
                  },
                  {
                    title: 'CRM',
                    to: ROUTES.FAVORITE_CRM,
                    new: true,
                  },
                ],
              },
              {
                title: 'Search Demand',
                to: ROUTES.SEARCH_DEMAND,
                icon: ScanSearch,
              },
              // {
              //   title: 'Weekly Products',
              //   to: ROUTES.WEEKLY_PRODUCTS,
              //   icon: CalendarFold,
              // },
              {
                title: 'Outreach Templates',
                to: 'https://drive.google.com/drive/folders/1aCbGwsRTxqqmzYO5vLUq9k6Qa1oC25Om?usp=sharing',
                icon: Users,
                blank: true,
              },
              {
                title: 'Help Desk',
                to: 'https://help.highticket.io',
                icon: LifeBuoy,
                blank: true,
              },
              // {
              //   title: 'Concierge Service',
              //   to: '/',
              //   icon: ConciergeBell,
              // },
            ]}
          />
        </div>
        <div>
          <CreditsCard className="mb-2" disabled={!isUserSubscriptionValid} />
          <UserMenu />
        </div>
      </aside>
      <header className="flex justify-between lg:hidden">
        <Link to="/" className="flex items-center gap-2">
          <Logo className="size-8" />
          <span className="text-lg font-semibold">Supplier HQ</span>
        </Link>
        <Sheet open={isMenuOpen} onOpenChange={setIsMenuOpen}>
          <SheetTrigger asChild>
            <Button variant="outline" size="icon" className="size-10">
              <Menu className="size-5 text-primary" />
              <span className="sr-only">Toggle navigation menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent className="bg-card pt-12">
            <ScrollArea className="flex h-[calc(100dvh-72px)] flex-col justify-between">
              <Nav
                disabled={!isUserSubscriptionValid}
                links={[
                  {
                    title: 'Dashboard',
                    icon: PackageSearch,
                    subLinks: [
                      {
                        title: 'Suppliers',
                        to: ROUTES.SUPPLIERS,
                        label: isSuppliersLoading
                          ? null
                          : suppliersData?.rowCount?.toString() ?? '0',
                      },
                      {
                        title: 'Distributors',
                        to: ROUTES.DISTRIBUTORS,
                        label: isDistributorsLoading
                          ? null
                          : distributorsData?.rowCount?.toString() ?? '0',
                      },
                    ],
                  },
                  {
                    title: 'Product Explorer',
                    to: ROUTES.PRODUCT_CATEGORIES,
                    icon: LayoutDashboard,
                  },
                  {
                    title: 'Favorites',
                    icon: Heart,
                    subLinks: [
                      {
                        title: 'Suppliers',
                        to: ROUTES.FAVORITE_SUPPLIERS,
                        label: isSuppliersFavoritesLoading
                          ? null
                          : suppliersFavoritesData?.length?.toString() ?? '0',
                      },
                      {
                        title: 'Distributors',
                        to: ROUTES.FAVORITE_DISTRIBUTORS,
                        label: isDistributorsFavoritesLoading
                          ? null
                          : distributorsFavoritesData?.length?.toString() ??
                            '0',
                      },
                      {
                        title: 'CRM',
                        to: ROUTES.FAVORITE_CRM,
                        new: true,
                      },
                    ],
                  },
                  {
                    title: 'Search Demand',
                    to: ROUTES.SEARCH_DEMAND,
                    icon: ScanSearch,
                  },
                  // {
                  //   title: 'Weekly Products',
                  //   to: ROUTES.WEEKLY_PRODUCTS,
                  //   icon: CalendarFold,
                  // },
                  {
                    title: 'Outreach Templates',
                    to: 'https://drive.google.com/drive/folders/1aCbGwsRTxqqmzYO5vLUq9k6Qa1oC25Om?usp=sharing',
                    icon: Users,
                    blank: true,
                  },
                  {
                    title: 'Help Desk',
                    to: 'https://help.supplierhq.io/en/',
                    icon: LifeBuoy,
                    blank: true,
                  },
                  // {
                  //   title: 'Concierge Service',
                  //   to: '/',
                  //   icon: ConciergeBell,
                  // },
                ]}
              />
              <div>
                <CreditsCard
                  className="mb-2"
                  disabled={!isUserSubscriptionValid}
                />
                <UserMenu />
              </div>
            </ScrollArea>
          </SheetContent>
        </Sheet>
      </header>
      <main className="overflow-y-auto lg:col-span-4">
        <Outlet />
      </main>
    </div>
  );
};
