import {
  Academy,
  AddOnCreditsCheckoutPayload,
  AddOnCreditsPayment,
  AddToFavoritesPayload,
  AdvancedSearch,
  Country,
  Favorite,
  FavoriteFolder,
  FavoritesType,
  ForgotPassword,
  ForgotPasswordPayload,
  ListOfProductTypesPayload,
  ListOfSellers,
  ListOfSellersPayload,
  ProductCategory,
  ProductType,
  ProductTypeDetail,
  RefreshTokenPayload,
  RemoveFromFavoritePayload,
  ResentSearchDemand,
  ResetPasswordPayload,
  SearchDemand,
  Seller,
  SignInPayload,
  SignUpPayload,
  SubscriptionCheckoutPayload,
  SuggestCorrectionPayload,
  UnlockPayload,
  UpdateFavoritePayload,
  UpdateFavoriteStatusPayload,
  UpdateFavoritesPayload,
  User,
  VotesPayload,
} from '.';
import { axios } from '../axios';

export const signIn = (data: SignInPayload) => {
  return axios.post<User>('/user/signin', data);
};

export const signUp = (data: SignUpPayload) => {
  return axios.post<User>('/user/signup', data);
};

export const forgotPassword = (data: ForgotPasswordPayload) => {
  return axios.post<ForgotPassword>('/user/forgot-password', data);
};

export const resetPassword = (data: ResetPasswordPayload) => {
  return axios.post<ForgotPassword>('/user/reset-password', data);
};

export const refreshToken = (data: RefreshTokenPayload) => {
  return axios.post<User>('/user/refresh-token', data);
};

export const userDetails = () => {
  return axios.get<User>('/user/getUserDetails');
};

export const listOfSellers = (data: ListOfSellersPayload) => {
  return axios.post<ListOfSellers>('/seller/listSellers', data);
};

export const supplierDetails = (id: number) => {
  return axios.get<Seller>(`/seller/contactSupplier/${id}`);
};

export const distributorDetails = (id: number) => {
  return axios.get<Seller>(`/seller/contactDistributor/${id}`);
};

export const listOfProductTypes = (data: ListOfProductTypesPayload) => {
  return axios.post<ProductType[]>('/seller/listProductTypes', data);
};

export const productTypeDetails = (id: number) => {
  return axios.post<ProductTypeDetail[] | ProductTypeDetail[][]>(
    '/seller/getProductTypeDetails',
    { categoryId: id }
  );
};

export const listOfProductCategories = () => {
  return axios.get<ProductCategory[]>('/seller/listCategories');
};

export const listOfCountries = () => {
  return axios.get<Country[]>('/seller/listCountries');
};

export const listOfDropShipsToCountries = () => {
  return axios.get<Country[]>('/seller/listDropShipsToCountries');
};

export const unlockSupplier = (data: UnlockPayload) => {
  return axios.post<string>(`/seller/unlock`, data);
};

export const listOfUnlockedSuppliers = () => {
  return axios.get<Seller[]>(`/seller/unlockedSuppliers`);
};

export const voteForSupplierContact = (id: number, vote: VotesPayload) => {
  return axios.post<string>(`/seller/contact/${id}/vote`, {
    vote: vote,
  });
};

export const listOfFavorites = (type?: FavoritesType, folderId?: string) => {
  return axios.get<Favorite[]>('/favorite/list', {
    params: {
      type,
      folderId,
    },
  });
};

export const suggestCorrection = (data: SuggestCorrectionPayload) => {
  return axios.post<string>('/seller/suggestion', data);
};

export const addToFavorites = (data: AddToFavoritesPayload) => {
  return axios.post('/favorite/add', data);
};

export const removeFromFavorites = (data: RemoveFromFavoritePayload) => {
  return axios.post('/favorite/remove', data);
};

export const updateFavoriteStatus = (data: UpdateFavoriteStatusPayload) => {
  return axios.post('/favorite/status', data);
};

export const updateFavorite = (id: number, data: UpdateFavoritePayload) => {
  return axios.patch(`/favorite/${id}`, data);
};

export const updateFavorites = (data: UpdateFavoritesPayload) => {
  return axios.patch('/favorite', data);
};

export const favoriteFolders = (type?: FavoritesType) => {
  return axios.get<FavoriteFolder[]>('/favorite/folders', {
    params: {
      type,
    },
  });
};

export const createFavoriteFolder = (name: string) => {
  return axios.post('/favorite/folders', {
    name,
  });
};

export const updateFavoriteFolder = (id: number, name: string) => {
  return axios.patch(`/favorite/folders/${id}`, {
    name,
  });
};

export const deleteFavoriteFolder = (id: number) => {
  return axios.delete(`/favorite/folders/${id}`);
};

export const createAddOnCreditsCheckoutSession = (
  data: AddOnCreditsCheckoutPayload
) => {
  return axios.post<AddOnCreditsPayment>(
    `/stripe/create-addoncredit-checkout-session`,
    data
  );
};

export const createSubscriptionCheckoutSession = (
  data: SubscriptionCheckoutPayload
) => {
  return axios.post<{ session: { id: string } }>(
    '/stripe/create-checkout-session',
    data
  );
};

export const createStripeCustomerPortal = (customerId: string) => {
  return axios.post<{ url: string }>('/stripe/create-customer-portal', {
    customerId,
  });
};

export const academy = () => {
  return axios.get<Academy>('/academy');
};

export const advancedSearch = (searchTerm: string) => {
  return axios.get<AdvancedSearch[]>('/seller/advancedSearch', {
    params: {
      search_term: searchTerm,
      supplier_limit: 5,
      distributor_limit: 5,
      product_type_limit: 20,
    },
  });
};

export const searchDemand = (searchTerm: string, country: string) => {
  return axios.get<SearchDemand>('/seller/trends', {
    params: {
      search_term: searchTerm,
      country,
      user_id: 2,
    },
  });
};

export const recentSearchDemand = () => {
  return axios.get<ResentSearchDemand[]>('/seller/recent-trend-searches');
};
