import { useQuery, useQueryClient } from '@tanstack/react-query';

import { ListOfSellersPayload, listOfSellers } from '@/lib/api';

const postSellers = async (payload: ListOfSellersPayload) => {
  const { data } = await listOfSellers(payload);
  return data;
};

const defaultPayload = {
  sortModel: [],
  priceRange: [0, 10000] as [number, number],
  limit: 10,
  product_type: [],
  search: '',
  skip: 0,
};

export const useSellers = (payload: ListOfSellersPayload) => {
  const queryClient = useQueryClient();

  payload = { ...defaultPayload, ...payload };

  const predictedPayload = {
    ...payload,
    limit: payload.limit,
    skip: payload.skip! + payload.limit!,
  };

  // NOTE: prefetch next page
  queryClient.prefetchQuery({
    queryKey: ['sellers', predictedPayload],
    queryFn: () => postSellers(predictedPayload),
  });

  return useQuery({
    queryKey: ['sellers', payload],
    queryFn: () => postSellers(payload),
  });
};
