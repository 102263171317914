import { Subscription } from '@/lib/api';

export const hasBasicAccess = (subscription?: Subscription) =>
  subscription === Subscription.BASIC ||
  subscription === Subscription.PREMIUM ||
  subscription === Subscription.ULTIMATE ||
  subscription === Subscription.ANNUAL ||
  subscription === Subscription.HTE_ANNUAL;

export const hasPremiumAccess = (subscription?: Subscription) =>
  subscription === Subscription.PREMIUM ||
  subscription === Subscription.ULTIMATE ||
  subscription === Subscription.ANNUAL ||
  subscription === Subscription.HTE_ANNUAL;

export const hasUltimateAccess = (subscription?: Subscription) =>
  subscription === Subscription.ULTIMATE;

export const hasAnnualAccess = (subscription?: Subscription) =>
  subscription === Subscription.ANNUAL;

export const hasHTEAnnualAccess = (subscription?: Subscription) =>
  subscription === Subscription.HTE_ANNUAL;
