export interface ApiError extends Error {
  response: {
    data: {
      message?: string;
      error?: string;
    };
  };
}
export interface SignInPayload {
  email: string;
  password: string;
}

export interface SignUpPayload {
  email: string;
  firstName: string;
  password: string;
}

export interface ForgotPasswordPayload {
  email: string;
}
export interface ResetPasswordPayload {
  email: string;
  password: string;
  token: string;
}

export interface ForgotPassword {
  message: string;
  status?: boolean;
}

export interface RefreshTokenPayload {
  refreshToken: string;
}

export interface RefreshToken {
  accessToken: string;
}

export enum PaymentStatus {
  PAID = 'paid',
  PENDING = 'pending',
  CANCELED = 'canceled',
}

export enum Subscription {
  BASIC = 'basic',
  PREMIUM = 'premium',
  ULTIMATE = 'ultimate',
  ANNUAL = 'annual',
  HTE_ANNUAL = 'HTEAnnual',
  SPECIAL_BASE = 'specialBase',
}

export interface User {
  email: string;
  name: string;
  onboarded: boolean;
  paymentStatus: PaymentStatus;
  status: boolean;
  subscription: Subscription;
  createdAt: string;
  accessToken: string;
  addOnCredits: number;
  creditsRemaining: number;
  customerId: string;
  subscriptionExpiresAt: string;
  totalCredits: number;
  churnkeyHash: string;
  trendSearchesUsed: number;
  trendSearchesRemaining: number;
}

export interface ListOfSellersPayload {
  category?: string[];
  country?: string[];
  dropShipsTo?: string[];
  product_type?: string[];
  searchDemand?: [number, number];
  avgMargin?: [number, number];
  priceRange?: [number, number];
  map?: boolean;
  dtc?: boolean;
  avgPrice?: [number, number];
  skip?: number;
  limit?: number;
  sortModel?: {
    field: string;
    sort: 'asc' | 'desc';
  }[];
  dateAdded?: number;
  type: FavoritesType;
  verifiedMargins?: boolean;
  unlockedSuppliersOnly?: boolean;
  search?: string;
}

export enum Votes {
  UPVOTE = 'upvotes',
  DOWNVOTE = 'downvotes',
}

export enum VotesPayload {
  UPVOTE = 'upvote',
  DOWNVOTE = 'downvote',
}
export interface Representative {
  downvotes?: number;
  email: string;
  firstName: string;
  id: number;
  isVerified: boolean;
  lastName: string;
  linkedin: string;
  phone: string;
  title: string;
  upvotes?: number;
  votedByUser?: VotesPayload;
}

export enum Status {
  NO_CONTACT = 'NoContact',
  REACHED_OUT = 'ReachedOut',
  IN_DISCUSSION = 'InDiscussion',
  FOLLOW_UP = 'FollowUp',
  SIGNED_UP = 'SignedUp',
}
export interface Seller {
  id: number;
  name: string;
  isUnlocked?: boolean;
  supplierType?: 'DTC' | string;
  website: string;
  verifiedMargins?: boolean;
  product_types?: string[];
  country: string;
  dropShipsTo?: string[];
  information: string;
  imgSrc?: string;
  address: string;
  state: string;
  zip: string;
  phone: string;
  email: string;
  city: string;
  representatives?: Representative[];
  suppliers?: Omit<Seller, 'suppliers'>[];
  avgMargin?: number;
  avgPrice?: number;
  map?: boolean;
  marginIncludesShipping?: boolean;
  priceRange?: string;
  searchDemand?: number;
  searchDemandKeywords?: string;
  createdAt: string;
  status?: Status;
}
export interface ListOfSellers {
  rowCount: number;
  sellers: Seller[];
}

export interface UnlockPayload {
  supplierId?: number;
  supplierIds?: number[];
}

export interface ListOfProductTypesPayload {
  categories: string[];
}

export interface ProductTypeDetail {
  averageCPC?: number;
  categories: ProductCategory[];
  createdAt: string;
  id: number;
  name: string;
  searchDemand?: number;
  topMonths?: number;
  priceRange?: number[];
  seasonality?: string;
  yearlyTrend?: string;
  averagePrice?: number;
}

export interface ProductType {
  id: string;
  name: string;
}

export interface AddOnCreditsCheckoutPayload {
  quantity: number;
}

export interface AddOnCreditsPayment {
  session: {
    id: string;
  };
}

export enum FavoritesType {
  SUPPLIER = 'supplier',
  DISTRIBUTOR = 'distributor',
}

export interface Favorite extends Seller {
  favId: number;
  id: number;
  tier: number;
  favoriteFolders: number[];
  type: FavoritesType;
}

export interface FavoriteFolder {
  folderId: number;
  folderName: string;
  favorites: string[];
}

export interface AddToFavoritesPayload {
  supplierId?: number;
  distributorId?: number;
  supplierIds?: number[];
  distributorIds?: number[];
  folderIds?: number[];
}

export interface RemoveFromFavoritePayload {
  id?: number;
  ids?: number[];
  type: FavoritesType;
}

export interface UpdateFavoriteStatusPayload {
  id: number;
  status: string;
}

export interface UpdateFavoritePayload {
  tier?: number;
  status?: string;
  folderList?: number[];
}

export interface UpdateFavoritesPayload {
  ids: number[];
  type: FavoritesType;
  folderList?: number[];
}

export interface SuggestCorrectionPayload {
  margin: string;
  marginIncludesShipping: string;
  comment?: string;
  supplier: string;
}

export interface SubscriptionCheckoutPayload {
  plan: string;
  custom: boolean;
}

export enum AcademyType {
  VIDEO = 'VIDEO',
  PDF = 'PDF',
  DOCX = 'DOCX',
  CSV = 'CSV',
}

export interface AcademyProduct {
  createdAt: string;
  description: string;
  fileLink?: string;
  id: number;
  imageUrl: string;
  title: string;
  type: string;
  videoLink?: string;
}

export interface Academy {
  AcademyType: AcademyProduct;
}

export interface ProductCategory {
  id: number;
  name: string;
  createdAt: string;
}

export interface Country {
  name: string;
  id: number;
}

export enum AdvancedSearchType {
  SELLER = 'seller',
  PRODUCT = 'product',
}

export enum AdvancedSearchGroup {
  SUPPLIER = 'supplier',
  DISTRIBUTOR = 'distributor',
  PRODUCT_TYPE = 'productType',
  CATEGORY = 'category',
}

export interface AdvancedSearch {
  group: AdvancedSearchGroup;
  type: AdvancedSearchType;
  rowCount: number;
  data?: {
    id: number | string;
    isUnlocked?: boolean;
    name: string;
    website?: string;
    imgSrc?: string;
  }[];
}

export interface ResentSearchDemand {
  name: string;
  date: string;
  date_15: string;
  volume: number;
  global_volume: number;
  cpc: number;
  competition: number;
  intent: number;
  keyword_difficulty: number;
  yearlyTrend: string;
  seasonality: string;
  updatedAt: string;
  country: string;
}

export interface SearchDemand {
  keyword: string;
  volume: string;
  globalVolume: number;
  cpc: string;
  competition: string;
  numberOfSearches: string;
  intent: string;
  keywordDifficulty: string;
  yearlyTrend: string;
  seasonality: string;
  volumeLastMonthOfPrevYear: string;
  countryVolumes: {
    country: string;
    volume: number;
  }[];
  trendSearchesUsed: number;
  trendSearchesRemaining: number;
}
